var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mts-application-form"},[(!_vm.formSubmissionResult)?[(_vm.formTitle)?_c('h2',{staticClass:"mts-application-form__title",domProps:{"innerHTML":_vm._s(_vm.formTitle)}}):_vm._e(),_vm._v(" "),(_vm.formDescription)?_c('div',{staticClass:"mts-application-form__description",domProps:{"innerHTML":_vm._s(_vm.formDescription)}}):_vm._e(),_vm._v(" "),_c('form',{class:['mts-application-form__form', { loading: _vm.isLoading === true }],attrs:{"id":_vm.formName,"name":_vm.formName},on:{"submit":_vm.onSubmit}},[_c('input',{attrs:{"id":`formId_${_vm.formName}`,"type":"hidden","name":"formId"},domProps:{"value":_vm.formIdValue}}),_vm._v(" "),_c('input',{attrs:{"id":"question","type":"hidden","name":"question"},domProps:{"value":_vm.formUserComment}}),_vm._v(" "),_c('input',{attrs:{"id":`fio_${_vm.formName}`,"type":"hidden","name":"fio"}}),_vm._v(" "),_c('input',{attrs:{"id":`google_cid_${_vm.formName}`,"type":"hidden","name":"google_cid"}}),_vm._v(" "),_c('input',{attrs:{"id":`locale_${_vm.formName}`,"type":"hidden","name":"locale","value":"ru"}}),_vm._v(" "),_vm._l((_vm.abTestHiddenFields),function(value,name){return _c('input',{key:name,attrs:{"name":name,"type":"hidden"},domProps:{"value":value}})}),_vm._v(" "),_c('div',{staticClass:"mts-application-form__form-fields"},[_c('div',{staticClass:"mts-application-form__field"},[_c('VInputMTS',{class:[
              _vm.formInputSize,
              {
                error: _vm.getErrors('firstname') && _vm.$v.form['firstname'].$dirty,
              },
            ],attrs:{"id":"name","name":"name","placeholder":"Имя","autocomplete":"name","type":"text"},on:{"focus":function($event){return _vm.$v.form['firstname'].$reset()}},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}}),_vm._v(" "),(_vm.getErrors('firstname') && _vm.$v.form['firstname'].$dirty)?_c('VErrorMTS',[_vm._v("\n            "+_vm._s(_vm.getErrors('firstname'))+"\n          ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"mts-application-form__field"},[_c('VInputMTS',{class:[
              _vm.formInputSize,
              { error: _vm.getErrors('email') && _vm.$v.form['email'].$dirty },
            ],attrs:{"id":"email","name":"email","placeholder":"Email","autocomplete":"email","type":"text"},on:{"focus":function($event){return _vm.$v.form['email'].$reset()}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_vm._v(" "),(_vm.getErrors('email') && _vm.$v.form['email'].$dirty)?_c('VErrorMTS',[_vm._v("\n            "+_vm._s(_vm.getErrors('email'))+"\n          ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"mts-application-form__field"},[_c('VPhoneInput',{class:[
              _vm.formInputSize,
              _vm.getErrors('phone') && _vm.$v.form['phone'].$dirty ? 'error' : '',
            ],attrs:{"get-errors":_vm.getErrors('phone')},on:{"getValid":_vm.getValidPhone}})],1)]),_vm._v(" "),_c('VButtonMTS',{staticClass:"mts-application-form__btn",class:[_vm.formBtnColor, _vm.formBtnSize],attrs:{"type":"submit"}},[_vm._v("\n        "+_vm._s(_vm.formBtnText)+"\n      ")]),_vm._v(" "),(_vm.formShowInfo)?_c('div',{staticClass:"mts-application-form__info"},[_vm._v("\n        Нажимая на кнопку «"+_vm._s(_vm.formBtnText)+"», вы принимаете условия\n        "),_c('a',{attrs:{"target":"_blank","href":"/legal/license-agreement.pdf"}},[_vm._v("оферты")]),_vm._v(",\n        соглашаетесь\n        "),_c('a',{attrs:{"target":"_blank","href":"/legal/private-agreement-soft.pdf"}},[_vm._v("на обработку персональных данных")]),_vm._v("\n        и связь с вами способами, указанными в оферте,\n        в целях исполнения условий оферты.\n      ")]):_vm._e()],2)]:_vm._e(),_vm._v(" "),(_vm.formSubmissionResult)?_c('MTSFormMessage',{attrs:{"type-message":_vm.statusFormText,"success":_vm.success,"message-for-success-application":_vm.messageForSuccessApplication}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }