<template>
  <div class="westudy-education">
    <div class="container">
      <div class="westudy-education__top">
        <h2 class="westudy-education__title">{{ title }}</h2>

        <div class="westudy-education__image">
          <picture>
            <source :srcset="image.srcMobileWebp" type="image/webp">
            <img :alt="image.alt" :src="image.srcMobile" :title="image.alt">
          </picture>
        </div>

        <ul class="westudy-education__cards">
          <li v-for="(item, index) in cards" :key="index" class="westudy-education__cards-item">
            <span v-html="item"></span>
          </li>
        </ul>
      </div>
    </div>

    <div class="westudy-education__bottom">
      <div class="container">
        <ul class="westudy-education__list">
          <li v-for="(item, index) in list" :key="index" class="westudy-education__list-item">
            <div class="westudy-education__list-item-title" v-html="item.title"></div>
            <div v-html="item.text" class="westudy-education__list-item-text"></div>
          </li>
        </ul>

        <VButtonLinkMTS href="#westudy-form" class="--violet westudy-education__btn">
          Попробовать бесплатно
        </VButtonLinkMTS>
      </div>
    </div>
  </div>
</template>

<script>
import WestudyEducationMixin from "@/components/Desktop/Products/Westudy/mixins/WestudyEducationMixin";
import VButtonLinkMTS from "@/components/Common/MTS/VButtonLinkMTS";

export default {
  name: 'WestudyEducationMobile',
  components: { VButtonLinkMTS },
  mixins: [WestudyEducationMixin],
}
</script>

<style lang="scss" scoped>
.westudy-education {
  border-radius: 24px;
  padding-top: 32px;
  margin-bottom: 32px;
  background-color: #0A0311;
  overflow: hidden;

  & .container {
    padding: 0 20px;
  }

  picture {
    display: block;
    line-height: 0;
  }

  &__bottom {
    background: linear-gradient(0.042turn, rgb(135, 67, 221) -6%, rgb(10, 3, 17) 26%);
    padding-bottom: 32px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    color: $color-white;
    max-width: 335px;
    margin: 0 auto 20px;
  }

  &__image {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__cards {
    display: grid;
    row-gap: 20px;
    list-style: none;
    padding: 0 0 64px;
    margin: 0;

    &-item {
      padding: 20px;
      background-color: #1D1A20;
      border-radius: 8px;
      text-align: center;

      span {
        display: block;
        font-size: 20px;
        line-height: 27px;
        color: $color-white;
      }
    }
  }

  &__list {
    display: grid;
    row-gap: 20px;
    list-style: none;
    padding: 0;
    margin: 0 0 32px;
    text-align: center;

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 32px;

      &-title {
        display: flex;
        align-items: flex-end;
        font-family: $font-mts-wide;
        font-size: 36px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.01em;
        color: $color-yellow;
        margin-bottom: 24px;
      }

      &-text {
        font-size: 20px;
        font-weight: 400;
        line-height: 27px;
        color: $color-white;
        max-width: 170px;

        ::v-deep .tablet {
          display: none;
        }

      }
    }
  }

  ::v-deep .v-button-link-mts {
    display: block;
    padding: 24px;
    width: 100%;
    border-radius: 12px;
    font-size: 20px;
    line-height: 24px;
  }

  &__btn {
    margin: 0 auto;
  }
}
</style>
