<template>
  <div class="westudy-platform">
    <h2 class="westudy-platform__title">{{ title }}</h2>

    <div class="westudy-platform__cards">
      <a
        v-for="(card, index) in cards"
        :href="card.link"
        :key="index"
        class="westudy-platform__cards-item"
      >
        <div v-if="card.image" class="westudy-platform__cards-item-image">
          <picture>
            <source :srcset="card.image.srcMobileWebp" type="image/webp" />
            <img :alt="card.image.alt" :src="card.image.srcMobile" :title="card.image.alt"/>
          </picture>
        </div>

        <template v-else>
          <h3 class="westudy-platform__cards-item-title">{{ card.title }}</h3>

          <div
            class="westudy-platform__cards-item-content"
            v-html="card.content"
          />

          <VIconMTS
            name="mts/icon-mts--arrow-link"
            width="24"
            height="24"
            fill="#1d2023"
            class="westudy-platform__cards-item-icon"
          />
        </template>
      </a>
    </div>
  </div>
</template>

<script>
import WestudyPlatformMixin from '@/components/Desktop/Products/Westudy/mixins/WestudyPlatformMixin'
import VIconMTS from '@/components/Common/MTS/VIconMTS'

export default {
  name: 'WestudyPlatformMobile',
  components: { VIconMTS },
  mixins: [WestudyPlatformMixin],
}
</script>

<style lang="scss" scoped>
.westudy-platform {
  margin-bottom: 32px;

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    max-width: 269px;
    margin: 0 auto 20px;
  }

  &__cards {
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      flex-direction: column;
      background-color: $color-white;
      padding: 20px;
      border-radius: 24px;
      transition: 0.3s ease-out;
      color: inherit;
      text-decoration: none;
      overflow: hidden;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: $color-yellow;
      }

      &:nth-child(1) {
      }

      &:nth-child(2) {
        padding: 0;
      }

      &:nth-child(3) {
        order: 4;
        padding: 0;
      }

      &:nth-child(4) {
        order: 3;
      }

      &:nth-child(5) {
        order: 5;
      }

      &:nth-child(6) {
        order: 6;
        padding: 0;
      }

      &-image {
        width: 100%;
        height: 340px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        max-width: 205px;
        margin-bottom: 16px;
      }

      &-content {
        font-size: 20px;
        line-height: 28px;

        ::v-deep(ul) {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            margin-bottom: 12px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &-icon {
        margin-top: 40px;
      }
    }
  }
}
</style>
