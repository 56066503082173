<template>
  <div
    v-click-outside="closeOptions"
    :class="['v-dropdown-mts', { active: showOptions }]"
  >
    <!-- <span class="v-dropdown-mts__label">{{ label }}</span> -->

    <div class="v-dropdown-mts__wrapper">
      <div
        class="v-dropdown-mts__selected"
        :class="{ 'v-dropdown-mts__selected--dark': value }"
        @click="toggleOptions"
      >
        {{ selectedOption }}
      </div>

      <div ref="options" :class="['v-dropdown-mts__options', wayToOpen]">
        <div class="v-dropdown-mts__options-inner">
          <ul class="v-dropdown-mts__options-list">
            <li class="v-dropdown-mts__options-list-item">
              {{ label }}
            </li>

            <li
              v-for="(option, index) in options"
              :key="index"
              class="v-dropdown-mts__options-list-item"
              @click="updateValue(option.value)"
            >
              {{ option.label }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <input type="hidden" :value="value" :name="name" />
  </div>
</template>

<script>
import clickOutside from '@/directives/clickOutside'

export default {
  name: 'VDropdownMTS',
  directives: { clickOutside },
  props: {
    value: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showOptions: false,
      rect: {},
      wayToOpen: '--bottom',
    }
  },
  computed: {
    selectedOption() {
      const option = this.options.find(
        (item) => this.value === item.value && item.value
      )
      return option?.label ?? this.label
    },
  },
  methods: {
    updateValue(option) {
      this.toggleOptions()
      if (!option) {
        return this.$emit('input', '')
      }
      this.$emit('input', option)
    },
    toggleOptions() {
      this.checkDistance()
      this.showOptions = !this.showOptions
    },
    closeOptions() {
      this.showOptions = false
      this.wayToOpen = '--bottom'

    },
    checkDistance() {
      const rect = this.$refs.options.getBoundingClientRect()
      const distanceToBottom = window.innerHeight - rect.top - rect.height
      this.wayToOpen = distanceToBottom < 0 ? '--top' : '--bottom'
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dropdown-mts {
  &__wrapper {
    position: relative;
  }

  &__selected {
    width: 100%;
    height: 44px;
    background: $color-gray-2;
    border-radius: 6px;
    border: 1px solid $color-gray-3;
    padding: 10px 46px 10px 12px;
    color: $color-gray-1;
    font-family: $font-mts-compact;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    transition: all 0.3s ease;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      right: 12px;
      top: calc(50% - 12px);
      display: block;
      background-image: url('~/assets/img/icons/mts/icon-mts--arrow-accordion.svg');
      background-size: 24px 24px;
      width: 24px;
      height: 24px;
      transform: rotate(180deg);
      transition: 0.3s;

      .active & {
        transform: rotate(0deg);
      }
    }

    .error &:before {
      content: '';
      position: absolute;
      right: 40px;
      top: calc(50% - 12px);
      display: block;
      background-image: url('~/assets/img/icons/mts/icon-mts--field-error.svg');
      background-size: 24px 24px;
      width: 24px;
      height: 24px;
    }

    &:not(.active &):not(.error &):hover {
      border: 1px solid $color-dark-2;
    }

    .active & {
      border: 1px solid $color-blue;
    }

    .error & {
      padding-right: 70px;
      border: 1px solid $color-red-3;
    }

    .xl & {
      height: 52px;
      padding: 13px 48px 13px 12px;
    }

    &--dark {
      color: #1d2023;
    }
  }

  &__options {
    position: absolute;
    left: 0;
    width: 100%;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12),
      0px 12px 20px 0px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    overflow: hidden;
    min-height: 0;
    padding: 6px;
    will-change: transform;
    background: $color-white;
    z-index: 970;
    opacity: 0;
    visibility: hidden;

    .active & {
      opacity: 1;
      visibility: visible;
    }

    &-inner {
      width: 100%;
      height: 100%;
      max-height: 600px;
      overflow-y: auto;
    }

    &-list {
      position: static;
      float: none;
      border: 0;
      padding: 0;
      margin: 0;

      &-item {
        border-radius: 12px;
        font-family: $font-mts-compact;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;

        &:not(:first-child) {
          padding: 14px 6px;
          color: $color-dark-2;
          transition: all 0.3s ease;
          cursor: pointer;

          &:hover {
            background-color: #bcc3d040;
          }
        }

        &:first-child {
          padding: 12px 6px 0;
          color: $color-gray-1;
        }
      }
    }
    &.--top {
      top: -8px;
      transform: translateY(-100%);
    }
    &.--bottom {
      bottom: -8px;
      transform: translateY(100%);
    }
  }
}
</style>
