<template>
  <div class="westudy-involvement">
    <h2 class="westudy-involvement__title">Вовлечение участников</h2>

    <div class="westudy-involvement__text">{{ text }}</div>

    <ul class="westudy-involvement__list">
      <li
        v-for="(item, index) in cards"
        :key="index"
        class="westudy-involvement__list-item"
      >
        <div v-if="item.image" class="westudy-involvement__list-item-image">
          <picture>
            <source :srcset="item.image.srcTabletWebp" type="image/webp" media="(max-width: 991px)">
            <source :srcset="item.image.srcTablet" type="image/jpg" media="(max-width: 991px)">
            <source :srcset="item.image.srcWebp" type="image/webp">
            <img :src="item.image.src" :alt="item.image.alt" :title="item.image.alt">
          </picture>
        </div>

        <template v-else>
          <h3 class="westudy-involvement__list-item-title">{{ item.title }}</h3>

          <div class="westudy-involvement__list-item-text">{{ item.text }}</div>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import WestudyInvolvementMixin from "@/components/Desktop/Products/Westudy/mixins/WestudyInvolvementMixin";

export default {
  name: 'WestudyInvolvementDesktop',
  mixins: [WestudyInvolvementMixin],
}
</script>

<style lang="scss" scoped>
.westudy-involvement {
  margin-bottom: 64px;

  @include screen-down('lg') {
    margin-bottom: 40px;
  }

  &__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    max-width: 600px;
    margin: 0 auto 40px;

    @include screen-down('lg') {
      margin-bottom: 32px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;

    @include screen-down('lg') {
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    &-item {
      display: flex;
      flex-direction: column;
      border-radius: 24px;
      background-color: $color-white;
      padding: 32px;
      overflow: hidden;

      @include screen-down('lg') {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &:nth-child(3) {
          order: 4;
        }

        &:nth-child(4) {
          order: 3;
        }

        &:nth-child(5) {
          order: 5;
        }

        &:nth-child(6) {
          order: 6;
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(6) {
        padding: 0;
      }

      &-title {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 16px;

        @include screen-down('lg') {
          margin-bottom: 40px;
        }
      }

      &-text {
        font-size: 20px;
        line-height: 27px;
        margin-top: auto;
      }

      &-image {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
