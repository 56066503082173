<template>
  <div class="westudy-promotion">
    <div class="container">
      <h2 class="westudy-promotion__title">Оформление <br>и продвижение</h2>

      <div class="westudy-promotion__image">
        <picture>
          <source :srcset="image.srcMobileWebp" type="image/webp">
          <img :src="image.srcMobile" :alt="image.alt"  :title="image.alt">
        </picture>
      </div>

      <div class="westudy-promotion__block">
        <span>{{ block }}</span>
      </div>

      <ul class="westudy-promotion__cards">
        <li
          v-for="(item, index) in cards"
          :key="index"
          class="westudy-promotion__cards-item"
        >
          <div class="westudy-promotion__cards-item-title" v-html="item.title"></div>
          <div class="westudy-promotion__cards-item-text">{{ item.text }}</div>
          <div class="westudy-promotion__cards-item-image">
            <picture>
              <source :srcset="item.image.srcMobileWebp" type="image/webp">
              <img :src="item.image.srcMobile" :alt="item.image.alt" :title="item.image.alt">
            </picture>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import WestudyPromotionMixin from "@/components/Desktop/Products/Westudy/mixins/WestudyPromotionMixin";

export default {
  name: 'WestudyPromotionMobile',
  mixins: [WestudyPromotionMixin],
}
</script>

<style lang="scss" scoped>
$tablet: 1010px;

.westudy-promotion {
  background-color: #0A0311;
  padding: 32px 0;
  color: $color-white;
  border-radius: 24px;
  margin-bottom: 32px;

  picture {
    display: block;
    line-height: 0;
  }

  & .container {
    padding: 0 20px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__image {
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 20px;
    background-color: $color-dark-2;
    padding: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  &__block {
    padding: 20px;
    border-radius: 16px;
    background-color: #1D1A20;
    margin-bottom: 20px;

    span {
      font-size: 20px;
      font-weight: 400;
      line-height: 27px;
    }
  }

  &__cards {
    list-style: none;
    padding: 0;
    margin: 0;

    &-item {
      display: flex;
      flex-direction: column;
      padding: 20px;
      background-color: #1D1A20;
      border-radius: 16px;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 16px;
      }

      &-text {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 16px;
      }

      &-image {
        width: 100%;
        margin-top: auto;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
